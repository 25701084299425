<template>
  <v-form
    ref="form"
    class="multi-col-validation"
    method="post"
    action="/"
    lazy-validation
    @submit.prevent="saveSettings()"
  >
    <v-card
      flat
      class="pa-3 mt-2"
    >
      <h1 class="text-center">
        System settings
      </h1>
      <v-card-text class="pt-5">
        <h2 class="font-weight-medium">
          Prepaid meters
        </h2>
      </v-card-text>
      <v-card-text class="pt-1">
        <v-label>Charges</v-label>
        <v-row class="mt-1">
          <v-col cols="2">
            <v-text-field
              v-model="form.prepaid_cost_per_unit"
              label="Cost per unit (in Ksh)"
              placeholder="130"
              dense
              outlined
              :rules="numberRules"
              :loading="isLoading"
              :disabled="isLoading"
              number
            ></v-text-field>
            <small
              v-show="form.errors.has('prepaid_cost_per_unit')"
              class="validation-error"
            >{{
              form.errors.get('prepaid_cost_per_unit')
            }}</small>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="can('service-charge-edit')">
        <v-label>Service charge</v-label>
        <v-row class="mt-1">
          <v-col cols="3">
            <v-select
              v-model="form.prepaid_service_charge_in"
              label="Service charge in"
              class="md-1"
              :items="serviceChargeOptions"
              item-text="name"
              item-value="value"
              outlined
              dense
              :loading="isLoading"
              :disabled="isLoading"
              @change="prepaid_service_chargeOptionChange()"
            ></v-select>
            <small
              v-show="form.errors.has('prepaid_service_charge_in')"
              class="validation-error"
            >{{
              form.errors.get('prepaid_service_charge_in')
            }}</small>
          </v-col>
        </v-row>
        <v-row
          v-for="(service_charge, index) in form.prepaid_service_charge"
          :key="index"
          class="mt-1"
        >
          <v-col md="2">
            <v-text-field
              v-model="service_charge.from"
              outlined
              dense
              label="From (Ksh)"
              placeholder="1"
              :rules="numberRules"
              :loading="isLoading"
              :disabled="isLoading"
              required
            />
          </v-col>
          <v-col>
            <p class="text-center mt-2">
              TO
            </p>
          </v-col>
          <v-col md="2">
            <v-text-field
              v-model="service_charge.to"
              outlined
              dense
              label="To (Ksh)"
              placeholder="100"
              :rules="numberRules"
              :loading="isLoading"
              :disabled="isLoading"
              required
            />
          </v-col>
          <v-col>
            <p
              class="text-center mt-1"
              style="font-size: 18px"
            >
              =
            </p>
          </v-col>
          <v-col md="3">
            <v-text-field
              v-model="service_charge.amount"
              :label="prepaid_service_chargeLabel"
              placeholder="130"
              dense
              outlined
              :rules="numberRules"
              :loading="isLoading"
              :disabled="isLoading"
            ></v-text-field>
          </v-col>
          <v-col md="4">
            <v-btn
              v-show="index === 0"
              class="ml-4"
              small
              outlined
              fab
              color="primary"
              @click="addPrepaidServiceChargeInput"
            >
              <v-icon>{{ icons.mdiPlus }}</v-icon>
            </v-btn>
            <v-btn
              v-show="index != 0"
              class="ml-4"
              small
              outlined
              fab
              color="primary"
              @click="removePrepaidServiceChargeInput(index)"
            >
              <v-icon>{{ icons.mdiClose }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <small
          v-show="form.errors.has('prepaid_service_charge')"
          class="validation-error"
        >{{
          form.errors.get('prepaid_service_charge')
        }}</small>
      </v-card-text><br />

      <v-divider></v-divider>

      <v-card-text class="pt-5">
        <h2 class="font-weight-medium">
          Post-paid meters
        </h2>
      </v-card-text>
      <v-card-text class="pt-1">
        <v-label>Charges</v-label>
        <v-row class="mt-1">
          <v-col cols="2">
            <v-text-field
              v-model="form.postpaid_cost_per_unit"
              label="Cost per unit (in Ksh)"
              placeholder="130"
              dense
              outlined
              :rules="numberRules"
              :loading="isLoading"
              :disabled="isLoading"
              number
            ></v-text-field>
            <small
              v-show="form.errors.has('prepaid_cost_per_unit')"
              class="validation-error"
            >{{
              form.errors.get('prepaid_cost_per_unit')
            }}</small>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="can('service-charge-edit')">
        <v-label>Service charge</v-label>
        <v-row class="mt-1">
          <v-col cols="3">
            <v-select
              v-model="form.postpaid_service_charge_in"
              label="Service charge in"
              class="md-1"
              :items="serviceChargeOptions"
              item-text="name"
              item-value="value"
              outlined
              dense
              :loading="isLoading"
              :disabled="isLoading"
              @change="postpaid_service_chargeOptionChange()"
            ></v-select>
            <small
              v-show="form.errors.has('postpaid_service_charge_in')"
              class="validation-error"
            >{{
              form.errors.get('postpaid_service_charge_in')
            }}</small>
          </v-col>
        </v-row>
        <v-row
          v-for="(service_charge, index) in form.postpaid_service_charge"
          :key="index"
          class="mt-1"
        >
          <v-col md="2">
            <v-text-field
              v-model="service_charge.from"
              outlined
              dense
              label="From (Ksh)"
              placeholder="1"
              :rules="numberRules"
              :loading="isLoading"
              :disabled="isLoading"
              required
            />
          </v-col>
          <v-col>
            <p class="text-center mt-2">
              TO
            </p>
          </v-col>
          <v-col md="2">
            <v-text-field
              v-model="service_charge.to"
              outlined
              dense
              label="To (Ksh)"
              placeholder="100"
              :rules="numberRules"
              :loading="isLoading"
              :disabled="isLoading"
              required
            />
          </v-col>
          <v-col>
            <p
              class="text-center mt-1"
              style="font-size: 18px"
            >
              =
            </p>
          </v-col>
          <v-col md="3">
            <v-text-field
              v-model="service_charge.amount"
              :label="postpaid_service_chargeLabel"
              placeholder="130"
              dense
              outlined
              :rules="numberRules"
              :loading="isLoading"
              :disabled="isLoading"
            ></v-text-field>
          </v-col>
          <v-col md="4">
            <v-btn
              v-show="index === 0"
              class="ml-4"
              small
              outlined
              fab
              color="primary"
              @click="addPostpaidServiceChargeInput"
            >
              <v-icon>{{ icons.mdiPlus }}</v-icon>
            </v-btn>
            <v-btn
              v-show="index != 0"
              class="ml-4"
              small
              outlined
              fab
              color="primary"
              @click="removePostpaidServiceChargeInput(index)"
            >
              <v-icon>{{ icons.mdiClose }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <small
          v-show="form.errors.has('prepaid_service_charge')"
          class="validation-error"
        >{{
          form.errors.get('prepaid_service_charge')
        }}</small>
      </v-card-text>
      <br />
      <v-card-text>
        <v-label>Meter reading message</v-label>
        <v-radio-group
          v-model="form.delay_meter_reading_sms"
          column
        >
          <v-radio
            label="Send meter reading message immediately after readings are added"
            value="0"
          ></v-radio>
          <v-radio
            label="Delay meter reading message for specified number of days after readings are added (recommended)"
            value="1"
          ></v-radio>
        </v-radio-group>
        <v-expand-transition>
          <v-row
            v-show="form.delay_meter_reading_sms == 1"
            class="ml-8"
          >
            <p class="text--primary body-1 mt-5">
              Send meter readings message to customers
            </p>
            <v-text-field
              v-model="form.meter_reading_sms_delay_days"
              placeholder="2"
              dense
              outlined
              :rules="meterReadingDateRules"
              :loading="isLoading"
              :disabled="isLoading"
              suffix="days"
              class="shrink number-input-3 ma-3"
            ></v-text-field>
            <p class="text--primary body-1 mt-5">
              after meter readings have been added
            </p>
            <small
              v-show="form.errors.has('meter_reading_sms_delay_days')"
              class="validation-error"
            >{{
              form.errors.get('meter_reading_sms_delay_days')
            }}</small>
          </v-row>
        </v-expand-transition>
      </v-card-text>
      <br />
      <v-card-text>
        <v-label>Bills</v-label>
        <v-row class="ml-4 mt-1">
          <p class="text--primary body-1 mt-5">
            Meter reading on
          </p>
          <v-text-field
            v-model="form.meter_reading_on"
            placeholder="25"
            dense
            outlined
            :rules="meterReadingDateRules"
            :loading="isLoading"
            suffix="th"
            :disabled="isLoading"
            :max="28"
            class="shrink number-input-2 ma-3"
          ></v-text-field>
        </v-row>
        <small
          v-show="form.errors.has('meter_reading_on')"
          class="validation-error"
        >{{
          form.errors.get('meter_reading_on')
        }}</small>
        <v-row class="ml-4 mt-1">
          <p class="text--primary body-1 mt-5">
            Bill due on
          </p>
          <v-text-field
            v-model="form.bill_due_on"
            placeholder="5"
            dense
            outlined
            suffix="th"
            :rules="numberRules"
            :loading="isLoading"
            :disabled="isLoading"
            class="shrink number-input ma-3"
          ></v-text-field>
          <small
            v-show="form.errors.has('bill_due_on')"
            class="validation-error"
          >{{
            form.errors.get('bill_due_on')
          }}</small>
        </v-row>
        <v-row class="ml-4 mt-1">
          <p class="text--primary body-1 mt-5">
            Tell customer meter disconnection is on
          </p>
          <v-text-field
            v-model="form.tell_user_meter_disconnection_on"
            placeholder="5"
            dense
            outlined
            suffix="th"
            :rules="numberRules"
            :loading="isLoading"
            :disabled="isLoading"
            class="shrink number-input ma-3"
          ></v-text-field>
          <small
            v-show="form.errors.has('tell_user_meter_disconnection_on')"
            class="validation-error"
          >{{
            form.errors.get('tell_user_meter_disconnection_on')
          }}</small>
        </v-row>
        <v-row class="ml-4 mt-1">
          <p class="text--primary body-1 mt-5">
            Actual meter disconnection on
          </p>
          <v-text-field
            v-model="form.actual_meter_disconnection_on"
            placeholder="8"
            dense
            outlined
            suffix="th"
            :rules="numberRules"
            :loading="isLoading"
            :disabled="isLoading"
            class="shrink number-input-2 ma-3"
          ></v-text-field>
          <small
            v-show="form.errors.has('actual_meter_disconnection_on')"
            class="validation-error"
          >{{
            form.errors.get('actual_meter_disconnection_on')
          }}</small>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-text class="pt-5">
        <h2 class="font-weight-medium">
          Connection fee
        </h2>
      </v-card-text>
      <v-card-text>
        <v-label>Connection fee bill remainder message</v-label>
        <v-radio-group
          v-model="form.send_connection_fee_bill_remainder_sms"
          column
        >
          <v-radio
            label="Do not send connection fee bill remainder message"
            value="0"
          ></v-radio>
          <v-radio
            label="Send connection fee bill remainder message days before bill due date"
            value="1"
          ></v-radio>
        </v-radio-group>
        <v-expand-transition>
          <v-row
            v-show="form.send_connection_fee_bill_remainder_sms == 1"
            class="ml-8"
          >
            <p class="text--primary body-1 mt-5">
              Send connection fee bill remainder message to customers
            </p>
            <v-text-field
              v-model="form.days_before_sending_connection_fee_bill_remainder_sms"
              placeholder="5"
              dense
              outlined
              :rules="meterReadingDateRules"
              :loading="isLoading"
              :disabled="isLoading"
              suffix="days"
              class="shrink number-input-3 ma-3"
            ></v-text-field>
            <p class="text--primary body-1 mt-5">
              before bill due date
            </p>
            <small
              v-show="form.errors.has('days_before_sending_connection_fee_bill_remainder_sms')"
              class="validation-error"
            >{{
              form.errors.get('days_before_sending_connection_fee_bill_remainder_sms')
            }}</small>
          </v-row>
        </v-expand-transition>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="pt-5">
        <h2 class="font-weight-medium">
          Payments
        </h2>
      </v-card-text>
      <v-card-text>
        <v-row class="ml-4 mt-1">
          <p class="text--primary body-1 mt-5">
            Notify new payments on
          </p>
          <v-text-field
            v-model="form.payment_notification_phone_number"
            label="Phone number"
            placeholder="07*********"
            dense
            outlined
            :rules="optionalPhoneRules"
            :loading="isLoading"
            :disabled="isLoading"
            :max="15"
            class="shrink ma-3"
          ></v-text-field>
        </v-row>
        <small
          v-show="form.errors.has('payment_notification_phone_number')"
          class="validation-error"
        >{{
          form.errors.get('payment_notification_phone_number')
        }}</small>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text>
        <v-btn
          color="primary"
          class="me-3 mt-3"
          type="submit"
          :loading="form.busy"
        >
          Save changes
        </v-btn>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import axios from 'axios'
import Form from 'vform'
import { mapGetters, mapActions } from 'vuex'
import { serialize } from 'object-to-formdata'
import { mdiPlus, mdiClose } from '@mdi/js'
import hasPermission from '@/mixins/hasPermission'
import validationRules from '@/mixins/validationRules'

export default {
  mixins: [validationRules, hasPermission],
  data() {
    return {
      isLoading: true,
      serviceChargeOptions: [
        {
          name: 'Fixed Amount',
          value: 0,
        },
        {
          name: 'Percentage',
          value: 1,
        },
      ],
      icons: {
        mdiPlus,
        mdiClose,
      },
      prepaid_service_chargeLabel: 'Service charge (in %)',
      postpaid_service_chargeLabel: 'Service charge (in Ksh)',
      form: new Form({
        prepaid_cost_per_unit: '',
        prepaid_service_charge_in: '',
        prepaid_service_charge: [
          {
            from: '',
            to: '',
            amount: '',
          },
        ],
        postpaid_cost_per_unit: '',
        postpaid_service_charge_in: '',
        postpaid_service_charge: [
          {
            from: '',
            to: '',
            amount: '',
          },
        ],
        meter_reading_on: '',
        tell_user_meter_disconnection_on: '',
        actual_meter_disconnection_on: '',
        bill_due_on: '',
        delay_meter_reading_sms: '',
        meter_reading_sms_delay_days: '',
        monthly_service_charge: '',
        send_connection_fee_bill_remainder_sms: '',
        days_before_sending_connection_fee_bill_remainder_sms: '',
        payment_notification_phone_number: '',
      }),
      activeTab: '',
      tabs: [],
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  mounted() {
    this.getSettings()
  },
  methods: {
    ...mapActions(['setMeterReadingDate']),
    getSettings() {
      this.isLoading = true
      axios
        .get('settings')
        .then(response => {
          response.data.settings.forEach(setting => {
            if (setting.key === 'bill_due_on') {
              this.form.bill_due_on = setting.value
            }
            if (setting.key === 'meter_reading_on') {
              this.form.meter_reading_on = setting.value
            }
            if (setting.key === 'tell_user_meter_disconnection_on') {
              this.form.tell_user_meter_disconnection_on = setting.value
            }
            if (setting.key === 'actual_meter_disconnection_on') {
              this.form.actual_meter_disconnection_on = setting.value
            }
            if (setting.key === 'delay_meter_reading_sms') {
              this.form.delay_meter_reading_sms = setting.value
            }
            if (setting.key === 'meter_reading_sms_delay_days') {
              this.form.meter_reading_sms_delay_days = setting.value
            }
            if (setting.key === 'monthly_service_charge') {
              this.form.monthly_service_charge = setting.value
            }
            if (setting.key === 'send_connection_fee_bill_remainder_sms') {
              this.form.send_connection_fee_bill_remainder_sms = setting.value
            }
            if (
              setting.key === 'days_before_sending_connection_fee_bill_remainder_sms'
            ) {
              this.form.days_before_sending_connection_fee_bill_remainder_sms = setting.value
            }
            if (setting.key === 'payment_notification_phone_number' && setting.value !== '0') {
              this.form.payment_notification_phone_number = setting.value
            }
          })
          response.data.meter_settings.forEach(meterSetting => {
            if (meterSetting.for === 'prepay') {
              this.form.prepaid_cost_per_unit = meterSetting.cost_per_unit
              this.form.prepaid_service_charge_in = meterSetting.service_charge_in_percentage
              this.form.prepaid_service_charge = meterSetting.service_charges
            }
            if (meterSetting.for === 'post-pay') {
              this.form.postpaid_cost_per_unit = meterSetting.cost_per_unit
              this.form.postpaid_service_charge_in = meterSetting.service_charge_in_percentage
              this.form.postpaid_service_charge = meterSetting.service_charges
            }
          })
          this.postpaid_service_chargeOptionChange()
          this.prepaid_service_chargeOptionChange()
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          this.$notification.error(error.response.data.message)
        })
    },
    saveSettings() {
      const isFormValid = this.$refs.form.validate()
      if (isFormValid) {
        this.form
          .submit('post', 'settings', {
            // Transform form objects to FormData
            transformRequest: [
              // eslint-disable-next-line no-unused-vars
              function (data, headers) {
                const { prepaid_service_charge } = data
                const { postpaid_service_charge } = data
                delete data.prepaid_service_charge
                delete data.postpaid_service_charge
                const serializedData = serialize(data)
                serializedData.append('prepaid_service_charge', JSON.stringify(prepaid_service_charge))
                serializedData.append('postpaid_service_charge', JSON.stringify(postpaid_service_charge))

                return serializedData
              },
            ],
          })
          .then(() => {
            this.$notification.success('Settings updated successfully')
            this.setMeterReadingDate(this.form.meter_reading_on)
          })
          .catch(error => {
            this.$notification.error(error.response.data.message)
          })
      }
    },
    postpaid_service_chargeOptionChange() {
      if (this.form.postpaid_service_charge_in === 0) {
        this.postpaid_service_chargeLabel = 'Service charge (in Ksh)'
      } else {
        this.postpaid_service_chargeLabel = 'Service charge (in %)'
      }
    },
    prepaid_service_chargeOptionChange() {
      if (this.form.prepaid_service_charge_in === 0) {
        this.prepaid_service_chargeLabel = 'Service charge (in Ksh)'
      } else {
        this.prepaid_service_chargeLabel = 'Service charge (in %)'
      }
    },
    addPrepaidServiceChargeInput() {
      if (this.form.prepaid_service_charge.length > 15) {
        this.$notification.error('You can only add a maximum of 5 price range')

        return
      }
      this.form.prepaid_service_charge.push({ from: '', to: '', amount: '' })
    },
    removePrepaidServiceChargeInput(index) {
      this.form.prepaid_service_charge.splice(index, 1)
    },
    addPostpaidServiceChargeInput() {
      if (this.form.postpaid_service_charge.length > 15) {
        this.$notification.error('You can only add a maximum of 5 price range')

        return
      }
      this.form.postpaid_service_charge.push({ from: '', to: '', amount: '' })
    },
    removePostpaidServiceChargeInput(index) {
      this.form.postpaid_service_charge.splice(index, 1)
    },
  },
}
</script>

<style scoped>
.number-input {
  width: 50px;
}
.number-input-2 {
  width: 60px;
}
.number-input-3 {
  width: 70px;
}
</style>
