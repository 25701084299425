<template>
  <v-card id="account-setting-card">
    <!-- tabs -->
    <v-tabs
      v-model="tab"
      show-arrows
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.path"
        :href="'#' + tab.path"
      >
        <v-icon
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item value="account">
        <account-settings></account-settings>
      </v-tab-item>

      <v-tab-item value="security">
        <security-settings></security-settings>
      </v-tab-item>

      <v-tab-item value="system">
        <system-settings></system-settings>
      </v-tab-item>

      <v-tab-item
        v-if="can('alert-contact-list')"
        value="alert-contacts"
      >
        <alert-contacts-settings></alert-contacts-settings>
      </v-tab-item>

      <v-tab-item
        v-if="can('system-user-list')"
        value="system-users"
      >
        <user-settings></user-settings>
      </v-tab-item>

      <v-tab-item value="roles">
        <roles-settings />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import {
  mdiAccountOutline, mdiLockOpenOutline, mdiApplicationCog, mdiInformationOutline, mdiAccountMultiple, mdiAdjust,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import hasPermission from '@/mixins/hasPermission'
import AccountSettings from './AccountSettings.vue'
import SecuritySettings from './SecuritySettings.vue'
import SystemSettings from './SystemSettings.vue'
import AlertContactsSettings from './AlertContactsSettings.vue'
import UserSettings from './UserSettings.vue'
import RolesSettings from './RolesSettings.vue'

export default {
  components: {
    AccountSettings,
    SecuritySettings,
    SystemSettings,
    UserSettings,
    AlertContactsSettings,
    RolesSettings,
  },
  mixins: [hasPermission],
  setup() {
    const tab = ref('')

    // tabs
    const tabs = [
      { title: 'Account', path: 'account', icon: mdiAccountOutline },
      { title: 'Security', path: 'security', icon: mdiLockOpenOutline },
      { title: 'System', path: 'system', icon: mdiApplicationCog },
    ]

    return {
      tab,
      tabs,
      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
        mdiAccountMultiple,
        mdiAdjust,
      },
    }
  },
  mounted() {
    if (this.can('alert-contact-list')) {
      this.tabs.push({ title: 'Alert Contacts', path: 'alert-contacts', icon: mdiInformationOutline })
    }
    if (this.can('system-user-list')) {
      this.tabs.push({ title: 'System Users', path: 'system-users', icon: mdiAccountMultiple })
    }
    if (this.can('role-list')) {
      this.tabs.push({ title: 'Roles', path: 'roles', icon: mdiAdjust })
    }
  },
}
</script>
